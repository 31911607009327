import { Divider, Empty, Modal, Space, Tag } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Loading from '../../components/Loading';
import { queryType, questionType } from '../../types';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    overflow-x: scroll;
    max-height: calc(100vh - 200px);
  }
`;

const StyledTag = styled(Tag)`
  cursor: pointer;
`;

const SentimentArray = [
  { label: 'Positive', code: 'positive' },
  { label: 'Negative', code: 'negative' },
  { label: 'Neutral', code: 'neutral' },
];

function CreateSegmentModal({
  visible,
  setVisible,
  questions,
  questionResponseOptions,
  saveSegment,
}) {
  const [selectedChoices, setSelectedChoices] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);

  const onCreateSegment = async () => {
    setIsModalLoading(true);
    if (Object.keys(selectedChoices).length) {
      await saveSegment(selectedChoices);
    }
    setIsModalLoading(false);
    setVisible(false);
    setSelectedChoices({});
  };

  const handleSegmentClick = ({ key, option, checked }) => {
    if (checked) {
      // if key exists, add option to choices array
      // if key does not exist, add key and option
      const updatedChoices = selectedChoices[key] ? [...selectedChoices[key], option] : [option];
      setSelectedChoices({ ...selectedChoices, [key]: updatedChoices });
    } else if (selectedChoices[key].length > 1) {
      // remove option from choices array
      setSelectedChoices({
        ...selectedChoices,
        [key]: selectedChoices[key].filter((o) => o !== option),
      });
    } else {
      // remove key if no options are selected
      const { [key]: remove, ...rest } = selectedChoices;
      setSelectedChoices({ ...rest });
    }
  };

  const responseOptions = () => {
    if (questionResponseOptions.some((query) => query.isLoading)) {
      return <Loading />;
    }
    if (questionResponseOptions.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No filtering questions were selected during the upload step"
        />
      );
    }
    return questions.map((question) => (
      <React.Fragment key={question.id}>
        <Divider />
        <Space direction="vertical">
          {question.text}
          <Space wrap>
            {questionResponseOptions
              .find((qro) => qro.data.questionId === question.id)
              .data.data.map((responseOption) => (
                <StyledTag
                  key={responseOption.text}
                  onClick={() =>
                    handleSegmentClick({
                      key: question.id,
                      option: responseOption.text,
                      checked: !selectedChoices[question.id]?.includes(responseOption.text),
                    })
                  }
                  color={
                    selectedChoices[question.id]?.includes(responseOption.text) ? '#87E8DE' : ''
                  }
                >
                  {responseOption.text}
                </StyledTag>
              ))}
          </Space>
        </Space>
      </React.Fragment>
    ));
  };

  return (
    <StyledModal
      title="New Segment"
      open={visible}
      width={780}
      onCancel={() => setVisible(false)}
      onOk={onCreateSegment}
      okText="Create Segment"
      confirmLoading={isModalLoading}
      destroyOnClose
      maskClosable={false}
    >
      Create a segment by selecting tags across answers to filtering questions
      {responseOptions()}
      <Divider />
      <Space direction="vertical">
        Sentiment
        <Space>
          {SentimentArray.map(({ label, code }) => (
            <StyledTag
              key={code}
              onClick={() =>
                handleSegmentClick({
                  key: 'sentiment',
                  option: code,
                  checked: !selectedChoices.sentiment?.includes(code),
                })
              }
              color={selectedChoices.sentiment?.includes(code) ? '#87E8DE' : ''}
            >
              {label}
            </StyledTag>
          ))}
        </Space>
      </Space>
    </StyledModal>
  );
}

CreateSegmentModal.propTypes = {
  questions: PropTypes.arrayOf(questionType).isRequired,
  questionResponseOptions: PropTypes.arrayOf(queryType).isRequired,
  saveSegment: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
};

export default CreateSegmentModal;
